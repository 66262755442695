<template>
  <main class="kb-main" id="kb-helpdesk-contact">
    <lxp-main-header :show-breadcrumb="true" :show-title="true"/>
    <!-- main-content -->
    <div class="main-content min-component">
      <div class="contact-container">

        <article v-for="(team,idx) in contactList" class="content-section" :key="idx">
          <header class="section-header header-divider">
            <h3 class="title">{{ team.title }}</h3>
          </header>
          <div class="contact-tablelist">
            <div class="kb-table kb-table-bordered">
              <table>
                <colgroup>
                  <col style="width:auto;">
                  <col style="width:140px;">
                  <col style="width:140px;">
                </colgroup>
                <tbody>
                <tr v-for="(member, midx) in team.member" :key="midx">
                  <td><span class="td-text" :class="{ 'kb-cell-text-bold' : member.leaderdiv!='M'}">{{ member.job_title }}</span></td>
                  <td><a><strong class="td-text">{{ member.name }}</strong></a></td>
                  <td><div class="td-actions"><a :href="`tel:${member.telno}`" class="kb-btn kb-btn-secondary kb-btn-sm rounded-lg" type="button"><i class="icon-call"></i><span class="text">{{ member.telno_view }}</span></a></div></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </article>

      </div>
    </div>
  </main>
</template>

<script>
import LxpMainHeader from '@/components/common/LxpMainHeader';
import {useStore} from "vuex";
import {computed} from 'vue';

export default {
  name: 'HelpContact',
  components: {LxpMainHeader},

  setup(){

    const store = useStore();
    const contactList = computed(() =>{ return store.state.help.contactList });

    return {
      contactList
    }
  }
};
</script>